export default {
  // Endpoints
  loginEndpoint: '/auth/local',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  forgotpassword: '/auth/forgot-password',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // Strpi API
  usersEndPoint: '/users',
  notificationEndpoint: '/notifications',
  prEndPoint: '/purchase-requests',
  poEndPoint: '/purchase-orders',
  quEndPoint: '/quotations',
  invEndPoint: '/invoices',
  borrowEndPoint: '/borrow-items',
  afpaEndPoint: '/ask-items',
  orderEndPoint: '/order-items',
  importRequestEndPoint: '/import-requests',
  exportRequestEndPoint: '/export-items',
  inboxEndPoint: '/inboxes',
  projectEndPoint: '/projects',
  compareEndPoint: '/compare-issues',
  supplierEndPoint: '/suppliers',
  customerEndPoint: '/customers',
  productMasterEndPoint: '/inventory-product-masters',
  productItemEndPoint: '/inventory-product-items',
  unitMasterEndPoint: '/unit-masters',
  siteEndPoint: '/site-masters',
  positionEndPoint: '/item-positions',
  tempPOEndPoint: '/temp-pos',
  tempIREndPoint: '/temp-irs',
  categoryEndPoint: '/category-masters',
  logEndPoint: '/item-logs',

  // Strapi Single
  systemConfigEndPoint: '/system-config',
}
