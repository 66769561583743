import i18n from '@/libs/i18n'

export default [
  {
    path: '/app-configuration',
    name: 'app-configuration',
    component: () => import('@/views/pages/system/appConfig/index.vue'),
    meta: {
      pageTitle: i18n.t('App Configuration'),
      breadcrumb: [
        {
          text: i18n.t('App Configuration'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-logs',
    name: 'system-logs',
    component: () => import('@/views/pages/system/logs/index.vue'),
    meta: {
      pageTitle: i18n.t('System Logs'),
      breadcrumb: [
        {
          text: i18n.t('System Logs'),
          active: true,
        },
      ],
    },
  },

]
