import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

export const setThemeConfig = args => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  if (userData) {
    userData.themeConfig = args
    userData.themeConfig.i18n = i18n && i18n.locale ? i18n.locale : undefined // เก็บข้อมูลภาษา
    useJwt.setThemeConfig(userData.themeConfig)

    return localStorage.setItem('userData', JSON.stringify(userData))
  }
  return null
}

export const getThemeConfig = () => (JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).themeConfig && JSON.parse(localStorage.getItem('userData')).themeConfig.layout ? JSON.parse(localStorage.getItem('userData')).themeConfig.layout : null)

export const setLangConfig = args => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  if (userData) {
    userData.themeConfig.i18n = args
    useJwt.setThemeConfig(userData.themeConfig)

    return localStorage.setItem('userData', JSON.stringify(userData))
  }
  return null
}

export const setUserData = args => {
  let userData = JSON.parse(localStorage.getItem('userData'))

  if (userData) {
    userData = args
    useJwt.setUserData(userData)

    return localStorage.setItem('userData', JSON.stringify(userData))
  }
  return null
}
