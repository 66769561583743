import i18n from '@/libs/i18n'

export default [
  {
    path: '/setting/profile',
    name: 'setting-profile',
    component: () => import('@/views/pages/settings/profile/Profile.vue'),
    meta: {
      pageTitle: i18n.t('Profile'),
      breadcrumb: [
        {
          text: i18n.t('Setting'),
        },
        {
          text: i18n.t('Profile'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting/edit-profile',
    name: 'edit-profile',
    component: () => import('@/views/pages/settings/profile/EditProfile.vue'),
    meta: {
      pageTitle: i18n.t('Edit Profile'),
      breadcrumb: [
        {
          text: i18n.t('Setting'),
        },
        {
          text: i18n.t('Profile'),
          to: { name: 'setting-profile' },
        },
        {
          text: i18n.t('Edit Profile'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-generate-code',
    name: 'setting-generate-code',
    component: () => import('@/views/pages/settings/generate/form/Generate.vue'),
    meta: {
      pageTitle: i18n.t('Setting'),
      breadcrumb: [
        {
          text: i18n.t('Setting'),
        },
        {
          text: i18n.t('Generate Code'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-user-approve',
    name: 'setting-user-approve',
    component: () => import('@/views/pages/settings/userApprove/form/UserApprove.vue'),
    meta: {
      pageTitle: i18n.t('Setting'),
      breadcrumb: [
        {
          text: i18n.t('Setting'),
        },
        {
          text: i18n.t('User Approve'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-payment',
    name: 'setting-payment',
    component: () => import('@/views/pages/settings/payment/form/Payment.vue'),
    meta: {
      pageTitle: i18n.t('Setting'),
      breadcrumb: [
        {
          text: i18n.t('Setting'),
        },
        {
          text: i18n.t('Payment'),
          active: true,
        },
      ],
    },
  },
]
