import { $themeConfig } from '@themeConfig'
import { setThemeConfig, getThemeConfig } from '@/store/localStorage'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: getThemeConfig() && getThemeConfig().isRTL ? getThemeConfig().isRTL : $themeConfig.layout.isRTL,
      skin: getThemeConfig() && getThemeConfig().skin ? getThemeConfig().skin : $themeConfig.layout.skin,
      routerTransition: getThemeConfig() && getThemeConfig().routerTransition ? getThemeConfig().routerTransition : $themeConfig.layout.routerTransition,
      type: getThemeConfig() && getThemeConfig().type ? getThemeConfig().type : $themeConfig.layout.type,
      contentWidth: getThemeConfig() && getThemeConfig().contentWidth ? getThemeConfig().contentWidth : $themeConfig.layout.contentWidth,
      menu: {
        hidden: getThemeConfig() && getThemeConfig().menu.hidden ? getThemeConfig().menu.hidden : $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: getThemeConfig() && getThemeConfig().navbar.type ? getThemeConfig().navbar.type : $themeConfig.layout.navbar.type,
        backgroundColor: getThemeConfig() && getThemeConfig().navbar.backgroundColor ? getThemeConfig().navbar.backgroundColor : $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: getThemeConfig() && getThemeConfig().footer.type ? getThemeConfig().footer.type : $themeConfig.layout.footer.type,
      },
    },
  },
  getters: {},
  mutations: {
    SETUP(state) {
      if (JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).themeConfig && JSON.parse(localStorage.getItem('userData')).themeConfig.layout) {
        state.layout = JSON.parse(localStorage.getItem('userData')).themeConfig.layout

        // rtl
        document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')

        if (state.layout && state.layout.skin && state.layout.skin === 'dark') document.body.classList.add('dark-layout')
        else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
      }
    },
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)

      // Save Themeconfig on Strapi
      setThemeConfig(state)
    },

  },
  actions: {},
}
