// import { setThemeConfig, getThemeConfig } from  '@/store/localStorage'
import useJwt from '@/auth/jwt/useJwt'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

export default {
  namespaced: true,
  state: {
    isClickDefaultApproverBtn: false,
  },
  getters: {},
  mutations: {
    SETUP(state) {
      useJwt.get(jwtDefaultConfig.systemConfigEndPoint).then(res => {
        if (res.status === 200) {
          state.isClickDefaultApproverBtn = res.data.isClickDefaultApproverBtn
          localStorage.setItem('system', JSON.stringify({ isClickDefaultApproverBtn: res.data.isClickDefaultApproverBtn }))
        } else {
          state.isClickDefaultApproverBtn = false
          localStorage.setItem('system', JSON.stringify({ isClickDefaultApproverBtn: false }))
        }
      })
    },

    // UPDATE_ISCLICKDEFAULTAPPROVERBTN(state, val) {
    //   state.isClickDefaultApproverBtn = val

    //   // Save Themeconfig on Strapi
    //   setThemeConfig(state)

    // },

  },
  actions: {},
}
