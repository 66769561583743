import Vue from 'vue'
import VueI18n from 'vue-i18n'
import defaultLanguage from '@/@core/app-config/i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function defaultLang() {
  const result = JSON.parse(localStorage.getItem('userData')) && JSON.parse(localStorage.getItem('userData')).themeConfig && JSON.parse(localStorage.getItem('userData')).themeConfig.i18n ? `${JSON.parse(localStorage.getItem('userData')).themeConfig.i18n}` : defaultLanguage
  return result
}

export default new VueI18n({
  locale: defaultLang(),
  fallbackLocale: defaultLang(),
  messages: loadLocaleMessages(),
})
