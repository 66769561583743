/* eslint-disable implicit-arrow-linebreak */
import i18n from '@/libs/i18n'

export default [
  {
    path: '/vendor-lists',
    name: 'vendor-lists',
    component: () => import('@/views/pages/erp/master/vendors/vendorTable/Lists.vue'),
    meta: {
      pageTitle: i18n.t('Supplier Lists'),
      breadcrumb: [
        {
          text: i18n.t('Supplier Table'),
        },
        {
          text: i18n.t('Supplier Lists'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/vendor-add',
    name: 'vendor-add',
    component: () => import('@/views/pages/erp/master/vendors/vendorForm/Add.vue'),
    meta: {
      pageTitle: i18n.t('Supplier Add'),
      breadcrumb: [
        {
          text: i18n.t('Supplier'),
        },
        {
          text: i18n.t('Supplier Lists'),
        },
        {
          text: i18n.t('Supplier Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/vendor-edit/:id',
    name: 'vendor-edit',
    component: () => import('@/views/pages/erp/master/vendors/vendorForm/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Supplier Edit'),
      breadcrumb: [
        {
          text: i18n.t('Supplier'),
        },
        {
          text: i18n.t('Supplier List'),
        },
        {
          text: i18n.t('Supplier Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/vendor-view/:id',
    name: 'vendor-view',
    component: () => import('@/views/pages/erp/master/vendors/vendorForm/View.vue'),
    meta: {
      pageTitle: i18n.t('Supplier Detail'),
      breadcrumb: [
        {
          text: i18n.t('Supplier'),
        },
        {
          text: i18n.t('Supplier List'),
        },
        {
          text: i18n.t('Supplier Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-lists',
    name: 'customer-lists',
    component: () => import('@/views/pages/erp/master/customers/customerTable/Lists.vue'),
    meta: {
      pageTitle: i18n.t('Customer Lists'),
      breadcrumb: [
        {
          text: i18n.t('Customer'),
        },
        {
          text: i18n.t('Customer Lists'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-add',
    name: 'customer-add',
    component: () => import('@/views/pages/erp/master/customers/customerForm/Add.vue'),
    meta: {
      pageTitle: i18n.t('Customer Add'),
      breadcrumb: [
        {
          text: i18n.t('Customer'),
        },
        {
          text: i18n.t('Customer Lists'),
        },
        {
          text: i18n.t('Customer Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/pages/erp/master/customers/customerForm/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Customer Edit'),
      breadcrumb: [
        {
          text: i18n.t('Customer'),
        },
        {
          text: i18n.t('Customer Lists'),
        },
        {
          text: i18n.t('Customer Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-view/:id',
    name: 'customer-view',
    component: () => import('@/views/pages/erp/master/customers/customerForm/ViewPage.vue'),
    meta: {
      pageTitle: i18n.t('Customer Detail'),
      breadcrumb: [
        {
          text: i18n.t('Customer'),
        },
        {
          text: i18n.t('Customer Lists'),
          to: { name: 'customer-lists' },
        },
        {
          text: i18n.t('Customer Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-issue',
    name: 'person-issue',
    component: () => import('@/views/pages/erp/purchase/compare/PersonCompare.vue'),
    meta: {
      pageTitle: i18n.t('Person Compare Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Compare Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-issue',
    name: 'all-issue',
    component: () => import('@/views/pages/erp/purchase/compare/AllCompare.vue'),
    meta: {
      pageTitle: i18n.t('All Compare Table'),
      breadcrumb: [
        {
          text: i18n.t('All Compare Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/issue-add',
    name: 'issue-add',
    component: () =>
      // import("@/views/pages/erp/purchase/compare/issueForm/Add.vue"),
      import('@/views/pages/erp/purchase/compare/Add.vue'),
    meta: {
      pageTitle: i18n.t('Issue Add'),
      breadcrumb: [
        {
          text: i18n.t('Issue'),
        },
        {
          text: i18n.t('Issue Lists'),
        },
        {
          text: i18n.t('Issue Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/issue-edit/:id',
    name: 'issue-edit',
    component: () => import('@/views/pages/erp/purchase/compare/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Issue Edit'),
      breadcrumb: [
        {
          text: i18n.t('Issue'),
        },
        {
          text: i18n.t('Issue Lists'),
        },
        {
          text: i18n.t('Issue Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/issue-detail/:id',
    name: 'issue-detail',
    component: () => import('@/views/pages/erp/purchase/compare/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Issue Detail'),
      breadcrumb: [
        {
          text: i18n.t('Issue'),
        },
        {
          text: i18n.t('Issue Lists'),
        },
        {
          text: i18n.t('Issue Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/score/:id',
    name: 'score',
    component: () => import('@/views/pages/erp/purchase/compare/scoreForm/AddScore.vue'),
    meta: {
      pageTitle: i18n.t('Score Form'),
      breadcrumb: [
        {
          text: i18n.t('Score'),
        },
        {
          text: i18n.t('Score Form'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/summary-lists',
    name: 'summary-lists',
    component: () => import('@/views/pages/erp/purchase/compare/SummaryCompare.vue'),
    meta: {
      pageTitle: i18n.t('Summary Lists'),
      breadcrumb: [
        {
          text: i18n.t('Summary'),
        },
        {
          text: i18n.t('Summary Lists'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/summary-detail/:id',
    name: 'summary-detail',
    component: () => import('@/views/pages/erp/purchase/compare/summaryForm/Summary.vue'),
    meta: {
      pageTitle: i18n.t('Summary Detail'),
      breadcrumb: [
        {
          text: i18n.t('Summary'),
        },
        {
          text: i18n.t('Summary Lists'),
        },
        {
          text: i18n.t('Summary Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-borrow',
    name: 'person-borrow',
    component: () => import('@/views/pages/erp/sales/borrow/PersonBorrow.vue'),
    meta: {
      pageTitle: i18n.t('Person Borrow Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Borrow Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-borrow',
    name: 'all-borrow',
    component: () => import('@/views/pages/erp/sales/borrow/AllBorrow.vue'),
    meta: {
      pageTitle: i18n.t('All Borrow Table'),
      breadcrumb: [
        {
          text: i18n.t('All Borrow Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-borrow-approval',
    name: 'person-borrow-approval',
    component: () => import('@/views/pages/erp/sales/borrow/PersonBorrowApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Borrow Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Borrow Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-borrow-approval',
    name: 'all-borrow-approval',
    component: () => import('@/views/pages/erp/sales/borrow/AllBorrowApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Borrow Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Borrow Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/borrow-add',
    name: 'borrow-add',
    component: () => import('@/views/pages/erp/sales/borrow/Add.vue'),
    meta: {
      pageTitle: i18n.t('Create Borrow'),
      breadcrumb: [
        {
          text: i18n.t('Borrow'),
          to: '/person-borrow',
        },
        {
          text: i18n.t('Create Borrow'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/borrow-detail/:id',
    name: 'borrow-detail',
    component: () => import('@/views/pages/erp/sales/borrow/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Borrow Detail'),
      breadcrumb: [
        {
          text: i18n.t('Borrow'),
          to: '/person-borrow',
        },
        {
          text: i18n.t('Borrow Detail'),
        },
      ],
    },
  },
  {
    path: '/borrow-edit/:id',
    name: 'borrow-edit',
    component: () => import('@/views/pages/erp/sales/borrow/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Borrow Edit'),
      breadcrumb: [
        {
          text: i18n.t('Borrow'),
          to: '/person-borrow',
        },
        {
          text: i18n.t('Borrow Edit'),
        },
      ],
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/pages/erp/inventory/traceItem/searchItem/Main.vue'),
    meta: {
      pageTitle: i18n.t('Search Item'),
      breadcrumb: [
        {
          text: i18n.t('Search'),
        },
        {
          text: i18n.t('Search Item'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/detail-item/:id',
    name: 'detail-item',
    component: () => import('@/views/pages/erp/inventory/traceItem/searchItem/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Item Detail'),
      breadcrumb: [
        {
          text: i18n.t('Search'),
        },
        {
          text: i18n.t('Item Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-log',
    name: 'asset-log',
    component: () => import('@/views/pages/erp/inventory/traceItem/assetLog/Main.vue'),
    meta: {
      pageTitle: i18n.t('Asset Log'),
      breadcrumb: [
        {
          text: i18n.t('Log'),
        },
        {
          text: i18n.t('Asset Log'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-export',
    name: 'person-export',
    component: () => import('@/views/pages/erp/inventory/exportedItem/PersonExport.vue'),
    meta: {
      pageTitle: i18n.t('Person Export Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Export Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-export',
    name: 'all-export',
    component: () => import('@/views/pages/erp/inventory/exportedItem/AllExport.vue'),
    meta: {
      pageTitle: i18n.t('All Export Table'),
      breadcrumb: [
        {
          text: i18n.t('All Export Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-export-approval',
    name: 'person-export-approval',
    component: () => import('@/views/pages/erp/inventory/exportedItem/PersonExportApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Export Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Export Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-export-approval',
    name: 'all-export-approval',
    component: () => import('@/views/pages/erp/inventory/exportedItem/AllExportApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Export Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Export Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/export-add',
    name: 'export-add',
    component: () => import('@/views/pages/erp/inventory/exportedItem/Add.vue'),
    meta: {
      pageTitle: i18n.t('Create Export'),
      breadcrumb: [
        {
          text: i18n.t('Export'),
          to: '/person-export',
        },
        {
          text: i18n.t('Create Export'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/export-detail/:id',
    name: 'export-detail',
    component: () => import('@/views/pages/erp/inventory/exportedItem/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Export Detail'),
      breadcrumb: [
        {
          text: i18n.t('Export'),
          to: '/person-export',
        },
        {
          text: i18n.t('Export Detail'),
        },
      ],
    },
  },
  {
    path: '/export-edit/:id',
    name: 'export-edit',
    component: () => import('@/views/pages/erp/inventory/exportedItem/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Export Edit'),
      breadcrumb: [
        {
          text: i18n.t('Export'),
          to: '/person-export',
        },
        {
          text: i18n.t('Export Edit'),
        },
      ],
    },
  },
  {
    path: '/export-pos',
    name: 'export-pos',
    component: () => import('@/views/pages/erp/inventory/exportedItem/POS/Main.vue'),
    meta: {
      pageTitle: i18n.t('Export POS'),
      breadcrumb: [
        {
          text: i18n.t('Export'),
        },
        {
          text: i18n.t('Export POS'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/export-confirm',
    name: 'export-confirm',
    component: () => import('@/views/pages/erp/inventory/exportedItem/POS/ConfirmExport.vue'),
    meta: {
      pageTitle: i18n.t('Export Confirm'),
      breadcrumb: [
        {
          text: i18n.t('Export'),
        },
        {
          text: i18n.t('Export Confirm'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory-dashboard',
    name: 'inventory-dashboard',
    component: () => import('@/views/pages/erp/inventory/dashboard/Main.vue'),
  },
  {
    path: '/purchase-dashboard',
    name: 'purchase-dashboard',
    component: () => import('@/views/pages/erp/purchase/dashboard/Main.vue'),
  },
  {
    path: '/person-purchase-order',
    name: 'person-purchase-order',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/PersonPo.vue'),
    meta: {
      pageTitle: i18n.t('Person Purchase Order Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Purchase Order Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-purchase-order',
    name: 'all-purchase-order',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/AllPo.vue'),
    meta: {
      pageTitle: i18n.t('All Purchase Order Table'),
      breadcrumb: [
        {
          text: i18n.t('All Purchase Order Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-purchase-order-approval',
    name: 'person-purchase-order-approval',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/PersonPoApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Purchase Order Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Purchase Order Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-purchase-order-approval',
    name: 'all-purchase-order-approval',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/AllPoApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Purchase Order Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Purchase Order Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-order-add',
    name: 'purchase-order-add',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/PoAdd.vue'),
    meta: {
      pageTitle: i18n.t('Create Purchase Order'),
      breadcrumb: [
        {
          text: i18n.t('Purchase Order'),
          to: '/person-purchase-order',
        },
        {
          text: i18n.t('Create Purchase Order'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-order-detail/:id',
    name: 'purchase-order-detail',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/PoDetail.vue'),
    meta: {
      pageTitle: i18n.t('Purchase Order Detail'),
      breadcrumb: [
        {
          text: i18n.t('Purchase Order'),
          to: '/person-purchase-order',
        },
        {
          text: i18n.t('Purchase Order Detail'),
        },
      ],
    },
  },
  {
    path: '/purchase-order-edit/:id',
    name: 'purchase-order-edit',
    component: () => import('@/views/pages/erp/purchase/purchaseOrder/PoEdit.vue'),
    meta: {
      pageTitle: i18n.t('Purchase Order Edit'),
      breadcrumb: [
        {
          text: i18n.t('Purchase Order'),
          to: '/person-purchase-order',
        },
        {
          text: i18n.t('Purchase Order Edit'),
        },
      ],
    },
  },
  {
    path: '/person-purchase-request',
    name: 'person-purchase-request',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/PersonPr.vue'),
    meta: {
      pageTitle: i18n.t('Person Purchase Request Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Purchase Request Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-purchase-approval',
    name: 'person-purchase-approval',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/PersonPrApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Purchase Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Purchase Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-request-add',
    name: 'purchase-request-add',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/PrAdd.vue'),
    meta: {
      pageTitle: i18n.t('Purchase Request Add Form'),
      breadcrumb: [
        {
          text: i18n.t('Purchase Request'),
          to: { name: 'person-purchase-request' },
        },
        {
          text: i18n.t('Purchase Request Add Form'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-request-edit/:id',
    name: 'purchase-request-edit',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/PrEdit.vue'),
    meta: {
      pageTitle: i18n.t('Purchase Request Edit Form'),
      breadcrumb: [
        {
          text: i18n.t('Purchase Request'),
          to: { name: 'person-purchase-request' },
        },
        {
          text: i18n.t('Purchase Request Edit Form'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-purchase-request',
    name: 'all-purchase-request',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/AllPr.vue'),
    meta: {
      pageTitle: i18n.t('All Purchase Request Table'),
      breadcrumb: [
        {
          text: i18n.t('All Purchase Request Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-purchase-approval',
    name: 'all-purchase-approval',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/AllPrApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Purchase Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Purchase Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-request-detail/:id',
    name: 'purchase-request-detail',
    component: () => import('@/views/pages/erp/purchase/purchaseRequest/PrDetail.vue'),
    meta: {
      pageTitle: i18n.t('Purchase Request Details'),
      breadcrumb: [
        {
          text: i18n.t('Purchase Request'),
          to: { name: 'person-purchase-request' },
        },
        {
          text: i18n.t('Purchase Request Details'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-master',
    name: 'product-master',
    component: () => import('@/views/pages/erp/inventory/productMaster/Index.vue'),
    meta: {
      pageTitle: i18n.t('Product Master'),
      breadcrumb: [
        {
          text: i18n.t('Product Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-master-add',
    name: 'product-master-add',
    component: () => import('@/views/pages/erp/inventory/productMaster/AddPage.vue'),
    meta: {
      pageTitle: i18n.t('Product Master'),
      breadcrumb: [
        {
          text: i18n.t('Product Master'),
          to: { name: 'product-master' },
        },
        {
          text: i18n.t('Product Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-master-edit/:id',
    name: 'product-master-edit',
    component: () => import('@/views/pages/erp/inventory/productMaster/EditPage.vue'),
    meta: {
      pageTitle: i18n.t('Product Master'),
      breadcrumb: [
        {
          text: i18n.t('Product Master'),
          to: { name: 'product-master' },
        },
        {
          text: i18n.t('Product Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-master-view/:id',
    name: 'product-master-view',
    component: () => import('@/views/pages/erp/inventory/productMaster/ViewPage.vue'),
    meta: {
      pageTitle: i18n.t('Product Master'),
      breadcrumb: [
        {
          text: i18n.t('Product Master'),
          to: { name: 'product-master' },
        },
        {
          text: i18n.t('Product Detail'),
          active: true,
        },
      ],
    },
  },
  // Unit Master
  {
    path: '/unit',
    name: 'unit',
    component: () => import('@/views/pages/erp/master/unit/UnitMaster.vue'),
    meta: {
      pageTitle: i18n.t('Unit Master'),
      breadcrumb: [
        {
          text: i18n.t('Unit Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit-add',
    name: 'unit-add',
    component: () => import('@/views/pages/erp/master/unit/UnitAdd.vue'),
    meta: {
      pageTitle: i18n.t('Unit Add Master'),
      breadcrumb: [
        {
          text: i18n.t('Unit Master'),
        },
        {
          text: i18n.t('Unit Add Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit-edit/:id',
    name: 'unit-edit',
    component: () => import('@/views/pages/erp/master/unit/UnitEdit.vue'),
    meta: {
      pageTitle: i18n.t('Unit Edit Master'),
      breadcrumb: [
        {
          text: i18n.t('Unit Master'),
        },
        {
          text: i18n.t('Unit Edit Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit-view/:id',
    name: 'unit-view',
    component: () => import('@/views/pages/erp/master/unit/UnitView.vue'),
    meta: {
      pageTitle: i18n.t('Unit View Master'),
      breadcrumb: [
        {
          text: i18n.t('Unit Master'),
        },
        {
          text: i18n.t('Unit View Master'),
          active: true,
        },
      ],
    },
  },
  // Item Position Master
  {
    path: '/item-position',
    name: 'item-position',
    component: () => import('@/views/pages/erp/master/itemPosition/ItemPositionMaster.vue'),
    meta: {
      pageTitle: i18n.t('Item Position Master'),
      breadcrumb: [
        {
          text: i18n.t('Item Position Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-position-add',
    name: 'item-position-add',
    component: () => import('@/views/pages/erp/master/itemPosition/ItemPositionAdd.vue'),
    meta: {
      pageTitle: i18n.t('Item Position Add Master'),
      breadcrumb: [
        {
          text: i18n.t('Item Position Master'),
        },
        {
          text: i18n.t('Item Position Add Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-position-edit/:id',
    name: 'item-position-edit',
    component: () => import('@/views/pages/erp/master/itemPosition/ItemPositionEdit.vue'),
    meta: {
      pageTitle: i18n.t('Item Position Edit Master'),
      breadcrumb: [
        {
          text: i18n.t('Item Position Master'),
        },
        {
          text: i18n.t('Item Position Edit Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-position-view/:id',
    name: 'item-position-view',
    component: () => import('@/views/pages/erp/master/itemPosition/ItemPositionView.vue'),
    meta: {
      pageTitle: i18n.t('Item Position View Master'),
      breadcrumb: [
        {
          text: i18n.t('Item Position Master'),
        },
        {
          text: i18n.t('Item Position View Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/category-lists',
    name: 'category-lists',
    component: () => import('@/views/pages/erp/master/category/List.vue'),
    meta: {
      pageTitle: i18n.t('Category List'),
      breadcrumb: [
        {
          text: i18n.t('Category'),
        },
        {
          text: i18n.t('Category List'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/category-add',
    name: 'category-add',
    component: () => import('@/views/pages/erp/master/category/CategoryAdd.vue'),
    meta: {
      pageTitle: i18n.t('Category Add'),
      breadcrumb: [
        {
          text: i18n.t('Category'),
        },
        {
          text: i18n.t('Category List'),
          to: { name: 'category-lists' },
        },
        {
          text: i18n.t('Category Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/category-edit/:id',
    name: 'category-edit',
    component: () => import('@/views/pages/erp/master/category/CategoryEdit.vue'),
    meta: {
      pageTitle: i18n.t('Category Edit'),
      breadcrumb: [
        {
          text: i18n.t('Category'),
        },
        {
          text: i18n.t('Category List'),
          to: { name: 'category-lists' },
        },
        {
          text: i18n.t('Category Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/category-detail/:id',
    name: 'category-detail',
    component: () => import('@/views/pages/erp/master/category/CategoryDetail.vue'),
    meta: {
      pageTitle: i18n.t('Category Detail'),
      breadcrumb: [
        {
          text: i18n.t('Category'),
        },
        {
          text: i18n.t('Category List'),
          to: { name: 'category-lists' },
        },
        {
          text: i18n.t('Category Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/site',
    name: 'site',
    component: () => import('@/views/pages/erp/master/site/SiteMaster.vue'),
    meta: {
      pageTitle: i18n.t('Site Master'),
      breadcrumb: [
        {
          text: i18n.t('Site Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/site-add',
    name: 'site-add',
    component: () => import('@/views/pages/erp/master/site/SiteAdd.vue'),
    meta: {
      pageTitle: i18n.t('Site Add Master'),
      breadcrumb: [
        {
          text: i18n.t('Site Master'),
        },
        {
          text: i18n.t('Site Add Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/site-edit/:id',
    name: 'site-edit',
    component: () => import('@/views/pages/erp/master/site/SiteEdit.vue'),
    meta: {
      pageTitle: i18n.t('Site Edit Master'),
      breadcrumb: [
        {
          text: i18n.t('Site Master'),
        },
        {
          text: i18n.t('Site Edit Master'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/site-view/:id',
    name: 'site-view',
    component: () => import('@/views/pages/erp/master/site/SiteView.vue'),
    meta: {
      pageTitle: i18n.t('Site View Master'),
      breadcrumb: [
        {
          text: i18n.t('Site Master'),
        },
        {
          text: i18n.t('Site View Master'),
          active: true,
        },
      ],
    },
  },
  // Project
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/pages/erp/project/Main.vue'),
    meta: {
      pageTitle: i18n.t('Job'),
      breadcrumb: [
        {
          text: i18n.t('Job'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/create-project-add',
    name: 'create-project-add',
    component: () => import('@/views/pages/erp/project/createdProject/ProjectAdd.vue'),
    meta: {
      pageTitle: i18n.t('Create Project Add'),
      breadcrumb: [
        {
          text: i18n.t('Job'),
        },
        {
          text: i18n.t('Create Project Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/update-project-form/:id',
    name: 'update-project-form',
    component: () => import('@/views/pages/erp/project/ProjectDetail.vue'),
    meta: {
      pageTitle: i18n.t('Update Project Form'),
      breadcrumb: [
        {
          text: i18n.t('Job'),
        },
        {
          text: i18n.t('Update Project Form'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-quotation',
    name: 'person-quotation',
    component: () => import('@/views/pages/erp/sales/quotation/PersonQu.vue'),
    meta: {
      pageTitle: i18n.t('Person Quotation Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Quotation Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-quotation',
    name: 'all-quotation',
    component: () => import('@/views/pages/erp/sales/quotation/AllQu.vue'),
    meta: {
      pageTitle: i18n.t('All Quotation Table'),
      breadcrumb: [
        {
          text: i18n.t('All Quotation Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-quotation-approval',
    name: 'person-quotation-approval',
    component: () => import('@/views/pages/erp/sales/quotation/PersonQuApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Quotation Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Quotation Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-quotation-approval',
    name: 'all-quotation-approval',
    component: () => import('@/views/pages/erp/sales/quotation/AllQuApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Quotation Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Quotation Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/quotation-add',
    name: 'quotation-add',
    component: () => import('@/views/pages/erp/sales/quotation/QuAdd.vue'),
    meta: {
      pageTitle: i18n.t('Create Quotation'),
      breadcrumb: [
        {
          text: i18n.t('Quotation'),
          to: '/person-quotation',
        },
        {
          text: i18n.t('Create Quotation'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/quotation-detail/:id',
    name: 'quotation-detail',
    component: () => import('@/views/pages/erp/sales/quotation/QuDetail.vue'),
    meta: {
      pageTitle: i18n.t('Quotation Detail'),
      breadcrumb: [
        {
          text: i18n.t('Quotation'),
          to: '/person-quotation',
        },
        {
          text: i18n.t('Quotation Detail'),
        },
      ],
    },
  },
  {
    path: '/quotation-edit/:id',
    name: 'quotation-edit',
    component: () => import('@/views/pages/erp/sales/quotation/QuEdit.vue'),
    meta: {
      pageTitle: i18n.t('Quotation Edit'),
      breadcrumb: [
        {
          text: i18n.t('Quotation'),
          to: '/person-quotation',
        },
        {
          text: i18n.t('Quotation Edit'),
        },
      ],
    },
  },
  {
    path: '/person-invoice',
    name: 'person-invoice',
    component: () => import('@/views/pages/erp/accounting/invoice/PersonIv.vue'),
    meta: {
      pageTitle: i18n.t('Person Invoice Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Invoice Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-invoice',
    name: 'all-invoice',
    component: () => import('@/views/pages/erp/accounting/invoice/AllIv.vue'),
    meta: {
      pageTitle: i18n.t('All Invoice Table'),
      breadcrumb: [
        {
          text: i18n.t('All Invoice Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-invoice-approval',
    name: 'person-invoice-approval',
    component: () => import('@/views/pages/erp/accounting/invoice/PersonIvApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Invoice Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Invoice Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-invoice-approval',
    name: 'all-invoice-approval',
    component: () => import('@/views/pages/erp/accounting/invoice/AllIvApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Invoice Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Invoice Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoice-add',
    name: 'invoice-add',
    component: () => import('@/views/pages/erp/accounting/invoice/IvAdd.vue'),
    meta: {
      pageTitle: i18n.t('Create Invoice'),
      breadcrumb: [
        {
          text: i18n.t('Invoice'),
          to: '/person-invoice',
        },
        {
          text: i18n.t('Create Invoice'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoice-detail/:id',
    name: 'invoice-detail',
    component: () => import('@/views/pages/erp/accounting/invoice/IvDetail.vue'),
    meta: {
      pageTitle: i18n.t('Invoice Detail'),
      breadcrumb: [
        {
          text: i18n.t('Invoice'),
          to: '/person-invoice',
        },
        {
          text: i18n.t('Invoice Detail'),
        },
      ],
    },
  },
  {
    path: '/invoice-edit/:id',
    name: 'invoice-edit',
    component: () => import('@/views/pages/erp/accounting/invoice/IvEdit.vue'),
    meta: {
      pageTitle: i18n.t('Invoice Edit'),
      breadcrumb: [
        {
          text: i18n.t('Invoice'),
          to: '/person-invoice',
        },
        {
          text: i18n.t('Invoice Edit'),
        },
      ],
    },
  },
  {
    path: '/cost-table',
    name: 'cost-table',
    component: () => import('@/views/pages/erp/accounting/cost/Cost.vue'),
    meta: {
      pageTitle: i18n.t('Cost'),
      breadcrumb: [
        {
          text: i18n.t('Cost Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cost-form',
    name: 'cost-form',
    component: () => import('@/views/pages/erp/accounting/cost/CostForm.vue'),
    meta: {
      pageTitle: i18n.t('Cost'),
      breadcrumb: [
        {
          text: i18n.t('Cost'),
        },
        {
          text: i18n.t('Cost Table'),
          to: { name: 'cost-table' },
        },
        {
          text: i18n.t('Cost Form'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cost-edit/:id',
    name: 'cost-edit',
    component: () => import('@/views/pages/erp/accounting/cost/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Cost'),
      breadcrumb: [
        {
          text: i18n.t('Cost'),
        },
        {
          text: i18n.t('Cost Table'),
          to: { name: 'cost-table' },
        },
        {
          text: i18n.t('Cost Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cost-detail/:id',
    name: 'cost-detail',
    component: () => import('@/views/pages/erp/accounting/cost/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Cost'),
      breadcrumb: [
        {
          text: i18n.t('Cost'),
        },
        {
          text: i18n.t('Cost Table'),
          to: { name: 'cost-table' },
        },
        {
          text: i18n.t('Cost Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cost-calendar',
    name: 'cost-calendar',
    component: () => import('@/views/pages/erp/accounting/cost/calendar/Calendar.vue'),
    meta: {
      pageTitle: i18n.t('Cost'),
      breadcrumb: [
        {
          text: i18n.t('Cost Calendar'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-lists',
    name: 'stock-lists',
    component: () => import('@/views/pages/erp/inventory/stock/stockTable/Stock.vue'),
    meta: {
      pageTitle: i18n.t('Stock Lists'),
      breadcrumb: [
        {
          text: i18n.t('Stock Item'),
        },
        {
          text: i18n.t('Stock Lists'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-item-lists/:id',
    name: 'stock-item-lists',
    component: () => import('@/views/pages/erp/inventory/stock/stockItemTable/StockItem.vue'),
    meta: {
      pageTitle: i18n.t('Stock Item List'),
      breadcrumb: [
        {
          text: i18n.t('Stock Item'),
        },
        {
          text: i18n.t('Stock Item List'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-item-print-label/:id',
    name: 'stock-item-print-label',
    component: () => import('@/views/pages/erp/inventory/stock/stockItemTable/PrintLabelPage.vue'),
    meta: {
      pageTitle: i18n.t('Stock Item List'),
      breadcrumb: [
        {
          text: i18n.t('Stock Item'),
        },
        {
          text: i18n.t('Stock Item List'),
          to: { name: 'stock-item-lists' },
        },
        {
          text: i18n.t('Print Label'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-add',
    name: 'stock-add',
    component: () => import('@/views/pages/erp/inventory/stock/stockForm/Add.vue'),
    meta: {
      pageTitle: i18n.t('Stock Add'),
      breadcrumb: [
        {
          text: i18n.t('Stock Item'),
        },
        {
          text: i18n.t('Stock Lists'),
          to: { name: 'stock-lists' },
        },
        {
          text: i18n.t('Stock Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-edit/:id',
    name: 'stock-edit',
    component: () => import('@/views/pages/erp/inventory/stock/stockForm/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Stock Edit'),
      breadcrumb: [
        {
          text: i18n.t('Stock Item'),
        },
        {
          text: i18n.t('Stock Lists'),
          to: { name: 'stock-lists' },
        },
        {
          text: i18n.t('Stock Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-detail/:id',
    name: 'stock-detail',
    component: () => import('@/views/pages/erp/inventory/stock/stockForm/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Stock Detail'),
      breadcrumb: [
        {
          text: i18n.t('Stock Item'),
        },
        {
          text: i18n.t('Stock Lists'),
          to: { name: 'stock-lists' },
        },
        {
          text: i18n.t('Stock Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-exchange-rate',
    name: 'setting-exchange-rate',
    component: () => import('@/views/pages/erp/currency/ExchangeForm.vue'),
    meta: {
      pageTitle: i18n.t('Setting'),
      breadcrumb: [
        {
          text: i18n.t('Setting'),
        },
        {
          text: i18n.t('Exchange Rate'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-import',
    name: 'person-import',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/PersonImport.vue'),
    meta: {
      pageTitle: i18n.t('Person Import Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Import Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-import',
    name: 'all-import',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/AllImport.vue'),
    meta: {
      pageTitle: i18n.t('All Import Table'),
      breadcrumb: [
        {
          text: i18n.t('All Import Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-import-approval',
    name: 'person-import-approval',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/PersonImportApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Import Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Import Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-import-approval',
    name: 'all-import-approval',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/AllImportApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Import Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Import Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-add',
    name: 'import-add',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/Add.vue'),
    meta: {
      pageTitle: i18n.t('Create Import'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
          to: '/person-import',
        },
        {
          text: i18n.t('Create Import'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-detail/:id',
    name: 'import-detail',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Import Detail'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
          to: '/person-import',
        },
        {
          text: i18n.t('Import Detail'),
        },
      ],
    },
  },
  {
    path: '/import-edit/:id',
    name: 'import-edit',
    component: () => import('@/views/pages/erp/inventory/importedItem/importRequest/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Import Edit'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
          to: '/person-import',
        },
        {
          text: i18n.t('Import Edit'),
        },
      ],
    },
  },
  {
    path: '/import-detail/:id/:kind',
    name: 'import-detail',
    component: () => import('@/views/pages/erp/inventory/importedItem/import/form/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Import Detail'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
        },
        {
          text: i18n.t('Import Lists'),
        },
        {
          text: i18n.t('Import Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-add/:id/:kind',
    name: 'import-add',
    component: () => import('@/views/pages/erp/inventory/importedItem/import/form/Add.vue'),
    meta: {
      pageTitle: i18n.t('Import Add'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
        },
        {
          text: i18n.t('Import Lists'),
        },
        {
          text: i18n.t('Import Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-by-po',
    name: 'import-by-po',
    component: () => import('@/views/pages/erp/inventory/importByPO/Main.vue'),
    meta: {
      pageTitle: i18n.t('Import By PO'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
        },
        {
          text: i18n.t('Import By PO'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-by-ir',
    name: 'import-by-ir',
    component: () => import('@/views/pages/erp/inventory/importByIR/Main.vue'),
    meta: {
      pageTitle: i18n.t('Import By IR'),
      breadcrumb: [
        {
          text: i18n.t('Import Item'),
        },
        {
          text: i18n.t('Import By IR'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-ask',
    name: 'person-ask',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/PersonAsk.vue'),
    meta: {
      pageTitle: i18n.t('Person Ask Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Ask Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-ask',
    name: 'all-ask',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/AllAsk.vue'),
    meta: {
      pageTitle: i18n.t('All Ask Table'),
      breadcrumb: [
        {
          text: i18n.t('All Ask Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-ask-approval',
    name: 'person-ask-approval',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/PersonAskApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Ask Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Ask Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-ask-approval',
    name: 'all-ask-approval',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/AllAskApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Ask Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Ask Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ask-add',
    name: 'ask-add',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/Add.vue'),
    meta: {
      pageTitle: i18n.t('Create Ask'),
      breadcrumb: [
        {
          text: i18n.t('Ask For Product Advance'),
          to: '/person-ask',
        },
        {
          text: i18n.t('Create Ask'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ask-detail/:id',
    name: 'ask-detail',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Ask Detail'),
      breadcrumb: [
        {
          text: i18n.t('Ask For Product Advance'),
          to: '/person-ask',
        },
        {
          text: i18n.t('Ask Detail'),
        },
      ],
    },
  },
  {
    path: '/ask-edit/:id',
    name: 'ask-edit',
    component: () => import('@/views/pages/erp/sales/askForProductAdvance/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Ask Edit'),
      breadcrumb: [
        {
          text: i18n.t('Ask For Product Advance'),
          to: '/person-ask',
        },
        {
          text: i18n.t('Ask Edit'),
        },
      ],
    },
  },
  {
    path: '/person-order',
    name: 'person-order',
    component: () => import('@/views/pages/erp/sales/orders/PersonOrder.vue'),
    meta: {
      pageTitle: i18n.t('Person Order Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Order Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-order',
    name: 'all-order',
    component: () => import('@/views/pages/erp/sales/orders/AllOrder.vue'),
    meta: {
      pageTitle: i18n.t('All Order Table'),
      breadcrumb: [
        {
          text: i18n.t('All Order Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/person-order-approval',
    name: 'person-order-approval',
    component: () => import('@/views/pages/erp/sales/orders/PersonOrderApproval.vue'),
    meta: {
      pageTitle: i18n.t('Person Order Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('Person Order Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/all-order-approval',
    name: 'all-order-approval',
    component: () => import('@/views/pages/erp/sales/orders/AllOrderApproval.vue'),
    meta: {
      pageTitle: i18n.t('All Order Approval Table'),
      breadcrumb: [
        {
          text: i18n.t('All Order Approval Table'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/order-add',
    name: 'order-add',
    component: () => import('@/views/pages/erp/sales/orders/Add.vue'),
    meta: {
      pageTitle: i18n.t('Create Order'),
      breadcrumb: [
        {
          text: i18n.t('Order'),
          to: '/person-order',
        },
        {
          text: i18n.t('Create Order'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/order-detail/:id',
    name: 'order-detail',
    component: () => import('@/views/pages/erp/sales/orders/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Order Detail'),
      breadcrumb: [
        {
          text: i18n.t('Order'),
          to: '/person-order',
        },
        {
          text: i18n.t('Order Detail'),
        },
      ],
    },
  },
  {
    path: '/order-edit/:id',
    name: 'order-edit',
    component: () => import('@/views/pages/erp/sales/orders/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Order Edit'),
      breadcrumb: [
        {
          text: i18n.t('Order'),
          to: '/person-order',
        },
        {
          text: i18n.t('Order Edit'),
        },
      ],
    },
  },
  {
    path: '/return-pos',
    name: 'return-pos',
    component: () => import('@/views/pages/erp/inventory/returnItem/POS/Main.vue'),
    meta: {
      pageTitle: i18n.t('Return POS'),
      breadcrumb: [
        {
          text: i18n.t('Return Item'),
        },
        {
          text: i18n.t('Return POS'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/return-confirm',
    name: 'return-confirm',
    component: () => import('@/views/pages/erp/inventory/returnItem/POS/ConfirmReturn.vue'),
    meta: {
      pageTitle: i18n.t('Return Confirm'),
      breadcrumb: [
        {
          text: i18n.t('Return Item'),
        },
        {
          text: i18n.t('Return Confirm'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-notifications',
    name: 'my-notifications',
    component: () => import('@/views/pages/erp/notification/myNotification.vue'),
    meta: {
      pageTitle: i18n.t('My Notification'),
      breadcrumb: [
        {
          text: i18n.t('Timeline'),
          active: true,
        },
      ],
    },
  },
]
