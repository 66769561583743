import i18n from '@/libs/i18n'

export default [
  {
    path: '/user-lists',
    name: 'user-lists',
    component: () => import('@/views/pages/management/users/table/Lists.vue'),
    meta: {
      pageTitle: i18n.t('User Lists'),
      breadcrumb: [
        {
          text: i18n.t('User'),
        },
        {
          text: i18n.t('User Lists'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-add',
    name: 'user-add',
    component: () => import('@/views/pages/management/users/form/Add.vue'),
    meta: {
      pageTitle: i18n.t('User Add'),
      breadcrumb: [
        {
          text: i18n.t('User'),
        },
        {
          text: i18n.t('User List'),
        },
        {
          text: i18n.t('User Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-detail/:id',
    name: 'user-detail',
    component: () => import('@/views/pages/management/users/form/Profile.vue'),
    meta: {
      pageTitle: i18n.t('User Detail'),
      breadcrumb: [
        {
          text: i18n.t('User'),
        },
        {
          text: i18n.t('User List'),
        },
        {
          text: i18n.t('User Detail'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-password/:id',
    name: 'edit-password',
    component: () => import('@/views/pages/management/users/form/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Edit Password'),
      breadcrumb: [
        {
          text: i18n.t('User'),
        },
        {
          text: i18n.t('User List'),
          to: { name: 'user-lists' },
        },
        {
          text: i18n.t('User Detail'),
        },
        {
          text: i18n.t('Edit Password'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-lists',
    name: 'role-lists',
    component: () => import('@/views/pages/management/roleApp/table/Lists.vue'),
    meta: {
      pageTitle: i18n.t('Role Lists'),
      breadcrumb: [
        {
          text: i18n.t('Role'),
        },
        {
          text: i18n.t('Role Lists'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-add',
    name: 'role-add',
    component: () => import('@/views/pages/management/roleApp/form/Add.vue'),
    meta: {
      pageTitle: i18n.t('Role Add'),
      breadcrumb: [
        {
          text: i18n.t('Role'),
        },
        {
          text: i18n.t('Role Lists'),
          to: { name: 'role-lists' },
        },
        {
          text: i18n.t('Role Add'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-edit/:id',
    name: 'role-edit',
    component: () => import('@/views/pages/management/roleApp/form/Edit.vue'),
    meta: {
      pageTitle: i18n.t('Role Edit'),
      breadcrumb: [
        {
          text: i18n.t('Role'),
        },
        {
          text: i18n.t('Role Lists'),
          to: { name: 'role-lists' },
        },
        {
          text: i18n.t('Role Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/role-detail/:id',
    name: 'role-detail',
    component: () => import('@/views/pages/management/roleApp/form/Detail.vue'),
    meta: {
      pageTitle: i18n.t('Role Detail'),
      breadcrumb: [
        {
          text: i18n.t('Role'),
        },
        {
          text: i18n.t('Role Lists'),
          to: { name: 'role-lists' },
        },
        {
          text: i18n.t('Role Detail'),
          active: true,
        },
      ],
    },
  },
]
